import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertPriceIncents',
})
export class ConvertPriceIncentsPipe implements PipeTransform {
  transform(value?: number, ...args: unknown[]): unknown {
    return value == undefined || value == null ? '0' : value / 100;
  }
}
