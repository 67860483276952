import { UserRoles } from './UserRoles';

export interface RouteDescription {
  label: FeatureLabel;
  icon: string;
  routerLink: string[];
  allowedUsers?: UserRoles[];
}

export type FeatureLabel =
  | 'Users'
  | 'Routines'
  | 'Tasks'
  | 'Posts'
  | 'Messages'
  | 'Discussions'
  | 'Files'
  | 'Reminders'
  | 'Quizzes'
  | 'Sensors'
  | 'Sensor Alerts'
  | 'Notifications'
  | 'Dashboards'
  | 'Group Messages'
  | 'Push Notifications'
  | 'Schedules'
  | 'Overviews'
  | 'Daily tasks'
  | 'Forms'
  | 'Shifts'
  | 'Day Tasks'
  | 'Forms'
  | 'Shop';

export const featurePriorities: FeatureLabel[] = [
  'Overviews',
  'Posts',
  'Messages',
  'Routines',
  'Discussions',
  'Sensors',
  'Notifications',
];

export var routeDescriptions = (): RouteDescription[] => [
  {
    label: 'Overviews',
    icon: 'pi pi-home',
    routerLink: ['/overviews'],
  },
  {
    label: 'Day Tasks',
    icon: 'pi pi-clipboard',
    routerLink: ['/day-tasks'],
  },
  {
    label: 'Users',
    icon: 'pi pi-user',
    routerLink: ['/users'],
  },
  {
    label: 'Routines',
    icon: 'pi pi-book',
    routerLink: ['/routines'],
  },
  {
    label: 'Tasks',
    icon: 'pi pi-fw pi-check-circle',
    routerLink: ['/tasks'],
    allowedUsers: ['admin', 'manager', 'superadmin'],
  },
  {
    label: 'Posts',
    icon: 'pi pi-globe',
    routerLink: ['/posts'],
  },
  {
    label: 'Messages',
    icon: 'pi pi-envelope',
    routerLink: ['/messages'],
  },
  {
    label: 'Discussions',
    icon: 'pi pi-comments',
    routerLink: ['/discussions'],
  },
  {
    label: 'Files',
    icon: 'pi pi-file',
    routerLink: ['/files'],
  },
  {
    label: 'Reminders',
    icon: 'pi pi-clock',
    routerLink: ['/reminders'],
  },
  {
    label: 'Quizzes',
    icon: 'pi pi-table',
    routerLink: ['/quizzes'],
  },
  {
    label: 'Sensors',
    icon: 'pi pi-wifi',
    routerLink: [`/devices`],
  },
  {
    label: 'Notifications',
    icon: 'pi pi-bell',
    routerLink: [`/notifications`],
  },
  {
    label: 'Shifts',
    icon: 'pi pi-calendar',
    routerLink: [`/schedules`],
  },
  {
    label: 'Forms',
    icon: 'pi pi-list-check',
    routerLink: [`/forms`],
  },
  {
    label: 'Shop',
    icon: 'pi pi-shop',
    routerLink: [`/shop`],
    allowedUsers: ['superadmin']
  },
];
