import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { FeatureLabel } from '../../core/constants/routeDescriptions';

@Directive({
  selector: '[appToggleFeature]'
})
export class ToggleFeatureDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthenticationService
  ) {}

  @Input() set appToggleFeature(featureLabel:FeatureLabel) {
    if (this.authService.showFeature(featureLabel)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
