import { Pipe, PipeTransform } from '@angular/core';
import { getTimeDifferenceInHoursAndMinutes } from '../../core/utils/date-options';

@Pipe({
  name: 'timeDifference',
})
export class TimeDifferencePipe implements PipeTransform {
  transform(firstTime: Date, ...args: unknown[]): string {
    if (args.length == 0) {
      return '-';
    }

    return `${getTimeDifferenceInHoursAndMinutes(
      firstTime,
      args[0] as string
    )}h`;
  }
}
