import { Injectable } from '@angular/core';
import { Observable, Subject, filter, map, tap } from 'rxjs';

interface BroadcastMessage {
  key: string;
  value: any;
}

@Injectable({ providedIn: 'root' })

/* just single subject to emit across application key-value pair */
export class BroadcasterService {
  private _eventQueue: Subject<BroadcastMessage>;

  constructor() {
    this._eventQueue = new Subject<BroadcastMessage>();
  }

  broadcast(key: any, value: any) {
    this._eventQueue.next({ key, value });
  }

  unsubscribe() {
    this._eventQueue.complete();
  }

  listen<T>(key: any): Observable<T> {
    return this._eventQueue.asObservable().pipe(
      filter((e) => e.key === key),
      map((e) => <T>e.value)
    );
  }
}