<p-sidebar
  [(visible)]="visible"
  position="right"
  [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
  styleClass="layout-profile-sidebar w-full sm:w-25rem"
>
  <ng-template pTemplate="header">
    <div
      id="sidebar-container"
      class="flex justify-content-center text-2xl align-items-center"
    >
      <img id="sidebar-logo" src="assets/icons/logo-no-text.jpg" />
      <p id="sidebar-title">TeamPal</p>
    </div>
  </ng-template>
  <div class="flex flex-column mx-auto md:mx-0">
    <span class="mb-2 font-semibold">Welcome</span>
    <span class="text-color-secondary font-medium mb-5 capitalize">{{
      authUser.fullName
    }}</span>

    <ul class="list-none m-0 p-0">
      <li (click)="navigateTotenant()">
        <a
          class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
        >
          <span>
            <i class="pi pi-building text-xl text-primary"></i>
          </span>
          <div class="ml-3">
            <span class="mb-2 font-semibold">{{authUser.tenantName}}</span>
          </div>
        </a>
      </li>
      <li (click)="navigateToprofileForUpdate()">
        <a
          class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
        >
          <span>
            <i class="pi pi-cog text-xl text-primary"></i>
          </span>
          <div class="ml-3">
            <span class="mb-2 font-semibold">Settings</span>
          </div>
        </a>
      </li>
      <li (click)="signOut()">
        <a
          class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
        >
          <span>
            <i class="pi pi-power-off text-xl text-primary"></i>
          </span>
          <div class="ml-3">
            <span class="mb-2 font-semibold">Sign Out</span>
          </div>
        </a>
      </li>
    </ul>
  </div>

  <div class="flex flex-column mt-5 mx-auto md:mx-0">
    <span class="mb-2 font-semibold">Navigations</span>

    <ul class="list-none m-0 p-0 flex flex-wrap justify-content-between">
      <li
        (click)="navigateTo('/tenant')"
        class="card h-8rem flex flex-column align-items-center justify-content-center nav-list-item mb-1 cursor-pointer"
      >
        <i class="pi pi-building text-primary text-4xl mb-2"></i>
        <span class="text-900 text-lg mb-2 font-medium">Tenant</span>
      </li>
      <span
        *ngFor="let routeDef of teampalFeatures"
        class="card h-8rem flex flex-column align-items-center justify-content-center nav-list-item mb-1 cursor-pointer"
      >
        <li
          (click)="navigateTo(routeDef.routerLink[0])"
          class="h-full w-full flex flex-column align-items-center justify-content-center"
        >
          <i class="text-primary text-4xl mb-2" [class]="routeDef.icon"></i>
          <span class="text-900 text-lg mb-2 font-medium">{{
            routeDef.label
          }}</span>
        </li>
      </span>
    </ul>
  </div>
</p-sidebar>
