import { Pipe, PipeTransform } from '@angular/core';
import { SensorTypes } from '../../core/models/devices/sensorTypes';

@Pipe({
  name: 'sensorComparison',
})
export class SensorComparisonPipe implements PipeTransform {
  transform(value: string, sensorType?: SensorTypes): string {
    if (sensorType && sensorType == 'Proximity') {
      return value.toLowerCase() == 'lessthan' ? 'Less than' : 'Greater than'
    }
    else if(sensorType && sensorType == 'LiquidLevel'){
      if(value=="Equal"){
        return value
      }
      else{
           return (value.toLowerCase() == 'lessthan' || value.toLowerCase() == 'less than') ? 'Less than' : 'Greater than'
      }
    }
    return (value.toLowerCase() == 'lessthan' || value.toLowerCase() == 'less than') ? 'Colder than' : 'Warmer than';
  }
}
