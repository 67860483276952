<div
  class="w-full h-full flex-wrap flex gap-2 my-2 justify-content-between cursor-pointer"
  *ngIf="post.postsMedias.length > 0"
>
  <div class="w-auto h-30rem m-auto" (click)="displayBasic = true">
    <video
      class="media-file"
      *ngIf="post.postsMedias[0].fileType == 'Video'; else showImage"
      controls
    >
      <source [src]="post.postsMedias[0].url" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <ng-template #showImage>
      <img class="media-file" [src]="post.postsMedias[0].url" />
    </ng-template>
  </div>
  <div class="w-full gap-2 hidden lg:flex" [class]="post.postsMedias.length>4?'justify-content-between':'justify-content-start'">
    <div
      class="media-container"
      *ngFor="let media of post.postsMedias.slice(1, 4)"
      (click)="displayBasic = true"
    >
      <video
        class="media-file"
        *ngIf="media.fileType == 'Video'; else showImage"
        controls
      >
        <source [src]="media.url" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <ng-template #showImage>
        <img class="media-file" [src]="media.url" />
      </ng-template>
    </div>
    <div
      class="media-container media-see-more relative"
      (click)="displayBasic = true"
      *ngIf="post.postsMedias.length > 5; else showLastMedia"
    >
      <video
        class="media-file"
        *ngIf="getMediaAtIndex(4).fileType == 'Video'; else showImage"
        controls
      >
        <source [src]="getMediaAtIndex(4).url" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <ng-template #showImage>
        <img class="media-file" [src]="getMediaAtIndex(5).url" />
      </ng-template>
      <div
        class="absolute top-0 h-full w-full flex justify-content-center align-items-center"
      >
        <span class="font-bold text-3xl text-white"
          >{{ post.postsMedias.length - 4 }}+</span
        >
      </div>
    </div>
    <ng-template #showLastMedia>
      <div
        class="media-container"
        (click)="displayBasic = true"
        *ngIf="getMediaAtIndex(4) as media"
      >
        <video
          class="media-file"
          *ngIf="media.fileType == 'Video'; else showImage"
          controls
        >
          <source [src]="media.url" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <ng-template #showImage>
          <img class="media-file" [src]="media.url" />
        </ng-template>
      </div>
    </ng-template>
  </div>
  <div class="w-full flex gap-2 lg:hidden" [class]="post.postsMedias.length>1?'justify-content-between':'justify-content-start'">
    <div
      class="w-6 h-10rem"
      *ngFor="let media of post.postsMedias.slice(1, 2)"
      (click)="displayBasic = true"
    >
      <video
        class="media-file"
        *ngIf="media.fileType == 'Video'; else showImage"
        controls
      >
        <source [src]="media.url" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <ng-template #showImage>
        <img class="media-file" [src]="media.url" />
      </ng-template>
    </div>
    <div
      class="w-6 h-10rem media-see-more relative"
      (click)="displayBasic = true"
      *ngIf="post.postsMedias.length >3; else showLastMediaMobile"
    >
      <video
        class="media-file"
        *ngIf="getMediaAtIndex(2).fileType == 'Video'; else showImage"
        controls
      >
        <source [src]="getMediaAtIndex(2).url" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <ng-template #showImage>
        <img class="media-file" [src]="getMediaAtIndex(2).url" />
      </ng-template>
      <div
        class="absolute top-0 h-full w-full flex justify-content-center align-items-center"
      >
        <span class="font-bold text-3xl text-white"
          >{{ post.postsMedias.length - 3 }}+</span
        >
      </div>
    </div>
    <ng-template #showLastMediaMobile>
      <div
        class="w-6 h-10rem"
        (click)="displayBasic = true"
        *ngIf="getMediaAtIndex(2) as media"
      >
        <video
          class="media-file"
          *ngIf="media.fileType == 'Video'; else showImage"
          controls
        >
          <source [src]="media.url" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <ng-template #showImage>
          <img class="media-file" [src]="media.url" />
        </ng-template>
      </div>
    </ng-template>
  </div>
</div>

<p-galleria
  [value]="post.postsMedias"
  [(visible)]="displayBasic"
  [responsiveOptions]="responsiveOptions"

  [containerStyle]="{ 'max-width': '640px' }" 
  [numVisible]="9"
  [circular]="true"
  [fullScreen]="true"
>
  <ng-template pTemplate="item" let-item>
    <video
      style="width: 100%; display: block"
      class="gallery-media-file"
      *ngIf="item.fileType == 'Video'; else showImage"
      controls
    >
      <source [src]="item.url" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <ng-template #showImage>
      <img
        class="gallery-media-file"
        style="width: 100%; display: block"
        [src]="item.url"
      />
    </ng-template>
  </ng-template>
  <ng-template pTemplate="thumbnail" let-item>
    <div class="grid grid-nogutter justify-content-center">
      <img
        class="gallery-media-thumbnail-file"
        style="display: block;background-color: white;"
        *ngIf="item.fileType == 'Video'; else showImage"
        src="assets/images/video.png"
      />
      <ng-template #showImage>
        <img class="gallery-media-thumbnail-file" style="display: block" [src]="item.url" />
      </ng-template>
    </div>
  </ng-template>
</p-galleria>
