import { Component, Input } from '@angular/core';
import { Post } from 'src/app/modules/core/models/posts/post';

@Component({
  selector: 'app-post-media-gallery',
  templateUrl: './post-media-gallery.component.html',
  styleUrls: ['./post-media-gallery.component.scss'],
})
export class PostMediaGalleryComponent {
  displayBasic: boolean =false;
  responsiveOptions: any[] = [
    {
        breakpoint: '1500px',
        numVisible: 5
    },
    {
        breakpoint: '1024px',
        numVisible: 3
    },
    {
        breakpoint: '768px',
        numVisible: 2
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
];
  @Input() post!: Post;
  getMediaAtIndex(index: number): any {
    return this.post.postsMedias && this.post.postsMedias.length >= index
      ? this.post.postsMedias[index]
      : null;
  }
}
