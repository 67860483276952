import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NoTenantAuth implements CanActivateChild {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    var authUser = this.authService.authUser;
    if (authUser == null) {
      this.router.navigate(['/auth/login']);
      return false;
    }
    return true;
  }
}
