import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ShopProductDto } from '../../core/models/shop/products/shopProductDto';
import { HttpClient } from '@angular/common/http';
import { ShopSettingsDto } from '../../core/models/shop/products/shopSettingsDto';

@Injectable({
  providedIn: 'root',
})
export class ShopProductsServiceService {
  constructor(private http: HttpClient) {}

  getShopProducts(): Observable<ShopProductDto[]> {
    return this.http.get<ShopProductDto[]>(`/shop/products`);
  }
  getShopSettings(): Observable<ShopSettingsDto> {
    return this.http.get<ShopSettingsDto>(`/shop/products/settings`);
  }
}
