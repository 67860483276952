import { USerRole } from '../models/users/userRole';

export type UserRoles = 'superadmin' | 'admin' | 'manager' | 'employees'|'assistantmanager'|'staff1'|'staff2'|'staff3'|'extra1'|'extra2'|'extra3';
export const userRolesList: USerRole[] = [
  {
    role: 1,
    name: 'superadmin',
  },
  {
    role: 2,
    name: 'admin',
  },
  {
    role: 3,
    name: 'manager',
  },
  {
    role: 4,
    name: 'employees',
  },
  {
    role: 5,
    name: 'assistantmanager',
  },
  {
    role: 6,
    name: 'staff1',
  },
  {
    role: 7,
    name: 'staff2',
  },
  {
    role: 8,
    name: 'staff3',
  },
  {
    role: 9,
    name: 'extra1',
  },
  {
    role: 10,
    name: 'extra2',
  },
  {
    role: 11,
    name: 'extra3',
  },
];
