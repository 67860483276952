<mat-toolbar class="bottm-nav p-1">
  <p-button
    *ngFor="let feature of fList"
    [icon]="feature.icon"
    [routerLink]="feature.routerLink"
    [rounded]="true"
    [text]="route.activeUrlSegment != getRouteSegment(feature)"
    size="large"
  ></p-button>
  <p-button
    (click)="onSettingsClick()"
    icon="pi pi-bars"
    [rounded]="true"
    [text]="!isSettings(route.activeUrlSegment)"
    size="large"
  ></p-button>
</mat-toolbar>
