import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '../../authentication/services/authentication.service';

@Directive({
  selector: '[appHideForAuthUser]',
})
export class HideForAuthUserDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthenticationService
  ) {}

  @Input() set appHideForAuthUser(userId: string) {
    if (this.authService.authUser!.appUserId != userId) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
