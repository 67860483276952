import { Pipe, PipeTransform } from '@angular/core';
import { getTimeFromDate } from '../../core/utils/date-options';

@Pipe({
  name: 'timeampmFromDate',
})
export class TimeampmFromDatePipe implements PipeTransform {
  transform(value: Date): string {
    return getTimeFromDate(value);
  }
}
