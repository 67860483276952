import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserRoles } from '../../core/constants/UserRoles';
import { AuthenticationService } from '../../authentication/services/authentication.service';

interface Inputs {
  roles: UserRoles[] | undefined;
  userids: string[] | undefined;
}

@Directive({
  selector: '[appShowForUserAndOwner]',
})
export class ShowForUserAndOwnerDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthenticationService
  ) {}

  @Input() set appShowForUserAndOwner(input: Inputs) {
    if (!input.roles && !input.userids) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }
    if (
      input.roles &&
      this.authService.authUser!.userRoles.find((r) => input.roles!.includes(r))
    ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (
      input.userids &&
      input.userids.find((u) => u == this.authService.authUser!.appUserId) !=
        null
    ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
