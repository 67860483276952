<div
  class="flex justify-content-center flex-column w-full align-items-center pt-3 h-5rem"
>
  <i *ngIf="icon" [class]="icon"></i>
  <div
    class="flex w-full flex-column align-items-center justify-content-center mt-2"
  >
    <p class="mb-4 text-center">{{ emptyMessage }}</p>
  </div>
</div>
