<div class="layout-container" [ngClass]="containerClass">
  <app-sidebar [teampalFeatures]="this.authService.teampalFeatures" [tenantLogoUrl]="authUser.tenantLogoUrl"></app-sidebar>
  <div class="layout-content-wrapper">
    <app-topbar [profileImage]="authUser.profilePicUrl"></app-topbar>

    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-profilemenu
    (signoutEvent)="signOut()"
    (navigateToProfileEvent)="navigateToProfile()"
    (navigateToUpdateProfileEvent)="navigateToUpdateProfile()"
    (navigateToTenantEvent)="navigateToTenant()"
    (navigateToEvent)="navigateTo($event)"
    [authUser]="authUser"
    [teampalFeatures]="this.authService.teampalFeatures"
  ></app-profilemenu>
  <div class="layout-mask"></div>
</div>

<div *ngIf="route.isParent" class="block w-full lg:hidden">
  <app-bottom-nav [teampalFeatures]="this.authService.teampalFeatures"></app-bottom-nav>
</div>
<p-confirmDialog></p-confirmDialog>
