import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthenticationGuard } from './modules/authentication/guards/authentication.guard';
import { NoTenantAuth } from './modules/authentication/guards/no-tenant-auth.guard';
import { SettingsComponent } from './modules/settings/components/settings/settings.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'overviews',
    pathMatch: 'full',
  },
  {
    path: 'settings',
    canActivateChild: [NoTenantAuth],
    component:SettingsComponent,
    data: { breadcrumb: 'Settings' },
    loadChildren: () =>
      import('./modules/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'users',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticationGuard],
    data: { breadcrumb: 'Users' },
    loadChildren: () =>
      import('./modules/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'routines',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticationGuard],
    data: { breadcrumb: 'Routines' },
    loadChildren: () =>
      import('./modules/routines/routines.module').then(
        (m) => m.RoutinesModule
      ),
  },
  {
    path: 'tenant',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticationGuard],
    data: { breadcrumb: 'Tenant' },
    loadChildren: () =>
      import('./modules/tenants/tenants.module').then((m) => m.TenantsModule),
  },
  {
    path: 'tasks',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticationGuard],
    data: { breadcrumb: 'Tasks' },
    loadChildren: () =>
      import('./modules/tasks/tasks.module').then((m) => m.TasksModule),
  },
  {
    path: 'posts',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticationGuard],
    data: { breadcrumb: 'Posts' },
    loadChildren: () =>
      import('./modules/posts/posts.module').then((m) => m.PostsModule),
  },
  {
    path: 'messages',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticationGuard],
    data: { breadcrumb: 'Messages' },
    loadChildren: () =>
      import('./modules/messages/messages.module').then(
        (m) => m.MessagesModule
      ),
  },
  {
    path: 'discussions',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticationGuard],
    data: { breadcrumb: 'Discussions' },
    loadChildren: () =>
      import('./modules/discussions/discussions.module').then(
        (m) => m.DiscussionsModule
      ),
  },
  {
    path: 'files',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticationGuard],
    data: { breadcrumb: 'Files' },
    loadChildren: () =>
      import('./modules/files/files.module').then((m) => m.FilesModule),
  },
  {
    path: 'reminders',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticationGuard],
    data: { breadcrumb: 'Reminders' },
    loadChildren: () =>
      import('./modules/reminders/reminders.module').then(
        (m) => m.RemindersModule
      ),
  },
  {
    path: 'quizzes',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticationGuard],
    data: { breadcrumb: 'Quizzes' },
    loadChildren: () =>
      import('./modules/quizz/quizz.module').then((m) => m.QuizzModule),
  },
  {
    path: 'devices',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticationGuard],
    data: { breadcrumb: 'Sensors' },
    loadChildren: () =>
      import('./modules/devices/devices.module').then((m) => m.DevicesModule),
  },
  {
    path: 'schedules',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticationGuard],
    data: { breadcrumb: 'Schedules' },
    loadChildren: () =>
      import('./modules/schedules/schedules.module').then((m) => m.SchedulesModule),
  },
  {
    path: 'notifications',
    component: AppLayoutComponent,
    canActivateChild: [AuthenticationGuard],
    data: { breadcrumb: 'Notifications' },
    loadChildren: () =>
      import('./modules/notifications/notifications.module').then(
        (m) => m.NotificationsModule
      ),
  },
  {
    path: 'overviews',
    component: AppLayoutComponent,
    data: { breadcrumb: 'Overviews' },
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import('./modules/overviews/overviews.module').then(
        (m) => m.OverviewsModule
      ),
  },
  {
    path: 'day-tasks',
    component: AppLayoutComponent,
    data: { breadcrumb: 'Day tasks' },
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import('./modules/day-tasks/day-tasks.module').then(
        (m) => m.DayTasksModule
      ),
  },
  {
    path: 'forms',
    component: AppLayoutComponent,
    data: { breadcrumb: 'Forms' },
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import('./modules/paper-lists/paper-lists.module').then(
        (m) => m.PaperListsModule
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'shop',
    component: AppLayoutComponent,
    data: { breadcrumb: 'Shop' },
    canActivateChild: [AuthenticationGuard],
    loadChildren: () =>
      import('./modules/shop/shop.module').then(
        (m) => m.ShopModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
