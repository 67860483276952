
  <div
    class="text-900 font-medium h-2rem text-xl line-height-3 mb-2 capitalize"
  >
    <p-skeleton
      width="10rem"
      styleClass="mb-2"
      borderRadius="16px"
    ></p-skeleton>
  </div>
  <div class="font-sm text-700 line-height-3 max-h-10rem flex flex-wrap capitalize">
    <p-skeleton
      width="10rem"
      styleClass="mb-2"
      borderRadius="16px"
    ></p-skeleton>
    <p-skeleton
      width="10rem"
      styleClass="mb-2"
      borderRadius="16px"
    ></p-skeleton>
    <p-skeleton
      width="10rem"
      styleClass="mb-2"
      borderRadius="16px"
    ></p-skeleton>
  </div>
  <div class="flex mt-4 justify-content-end h-3rem align-items-center">
    <p-skeleton shape="circle" size="3rem" styleClass="mr-2"></p-skeleton>
    <div class="ml-2">
      <div class="text-xs font-bold text-900 capitalize">
        <p-skeleton
          width="5rem"
          styleClass="mb-2"
          borderRadius="16px"
        ></p-skeleton>
      </div>
      <div class="text-xs flex align-items-center text-700">
        <p-skeleton
          width="5rem"
          styleClass="mb-2"
          borderRadius="16px"
        ></p-skeleton>
      </div>
    </div>
  </div>

