import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscribeToMessagesPushNotificationsRequest } from '../../core/models/notifications/subscribeToMessagesPushNotificationsRequest';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private http: HttpClient) {}

  cachedNotificationStatus(): boolean {
    return localStorage.getItem('pushNotificationsEnabled') == 'false'
      ? false
      : true;
  }
  subscribeToPushNotifications(
    request: SubscribeToMessagesPushNotificationsRequest
  ): Observable<any> {
    return this.http.put<any>(`/notifications/push/subscribe`, request);
  }
  setNotificationStatus(status: boolean) {
    localStorage.setItem('pushNotificationsEnabled', status.toString());
  }
  isSubscribedToPushNotifications(): Observable<boolean> {
    return this.http.get<boolean>(`/notifications/push/status`);
  }
  unSubscribeToPushNotifications(): Observable<any> {
    return this.http.put<any>(`/notifications/push/unsubscribe`, null);
  }
}
