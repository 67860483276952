import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AuthUser } from '../models/users/authUser';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private analytics: AngularFireAnalytics) {}

  setGAUser(authUser?: AuthUser) {
    if (authUser) {
      this.analytics.setAnalyticsCollectionEnabled(true);
      this.analytics.setUserId(authUser.teamPalUserId);
      this.analytics.setUserProperties(authUser);
    } else {
      this.analytics.setAnalyticsCollectionEnabled(false);
    }
  }
}
