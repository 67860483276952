import { Pipe, PipeTransform } from '@angular/core';
import { getTimeIn24FromDate } from '../../core/utils/date-options';

@Pipe({
  name: 'timein24FromDate',
})
export class Timein24FromDatePipe implements PipeTransform {
  transform(value: Date): string {
    return getTimeIn24FromDate(value);
  }
}