export const environment = {
  production: true,
  apiUrl: 'https://test.app.api.teampal.se/api',
  api: 'https://test.app.api.teampal.se',
  pushNotificationsPublicKey:
    'BGeQxuSwHGmXwBpCGECd_WHykoc_-92Tc8CVbzMyEBlUsiCHXZ-adxdzDIHrayxt3FRbyNUTKr3g-LFKs4-Olmc',
  firebaseConfig: {
    apiKey: 'AIzaSyBp_sEkrTkjPVDzH9AJ50ovm0ComMzavFo',
    authDomain: 'teampal-testing.firebaseapp.com',
    projectId: 'teampal-testing',
    storageBucket: 'teampal-testing.appspot.com',
    messagingSenderId: '890546877317',
    appId: '1:890546877317:web:fb439010be096b4682caec',
    measurementId: 'G-1EMX4B6VVZ',
  },
};
