import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  isSmall: boolean = false;
  constructor(
    private messageService: MessageService,
    private _snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver
      .observe([
        Breakpoints.Large, // predefined breakpoint for small screens
      ])
      .subscribe((result) => {
        if (result.matches) {
          this.isSmall = false;
        } else {
          this.isSmall = true;
        }
      });
  }

  errorToast(header: string, message: string) {
    if (this.isSmall) {
      this._snackBar.open(message,'Close', {
        duration: 3000,
        verticalPosition:'bottom'
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: header,
        detail: message,
      });
    }
  }
  successToast(message: string, header: string = 'Success') {
    if (this.isSmall) {
      this._snackBar.open(message, 'Close', {
        duration: 3000,
        verticalPosition:'bottom'
      });
    } else {
      this.messageService.add({
        severity: 'success',
        summary: header,
        detail: message,
      });
    }
  }
}
