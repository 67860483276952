import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { BaseComponent } from 'src/app/modules/core/components/base/base.component';
import { CreateDayTaskRequest } from 'src/app/modules/core/models/day-tasks/createDayTaskRequest';
import { DayTaskDto } from 'src/app/modules/core/models/day-tasks/dayTaskDto';
import { UpdateDayTaskDetailsRequest } from 'src/app/modules/core/models/day-tasks/updateDayTaskDetailsRequest';
import { AppUser } from 'src/app/modules/core/models/users/appUser';
import { AppUserBasics } from 'src/app/modules/core/models/users/appUserBasics';
import { BroadcasterService } from 'src/app/modules/core/services/broadcaster.service';
import { ToastService } from 'src/app/modules/core/services/toast.service';
import { formatToLocalDateString } from 'src/app/modules/core/utils/date-options';
import { DayTasksService } from 'src/app/modules/day-tasks/services/day-tasks.service';
import { UserService } from 'src/app/modules/users/services/user.service';

@Component({
  selector: 'app-manage-day-task-shared',
  templateUrl: './manage-day-task-shared.component.html',
  styleUrls: ['./manage-day-task-shared.component.scss'],
})
export class ManageDayTaskSharedComponent
  extends BaseComponent
  implements OnInit
{
  createTaskForm!: FormGroup;
  updateTaskForm!: FormGroup;
  filteredUsers: AppUser[] = [];
  manageTask?: DayTaskDto;
  operation!: string;
  @ViewChild('targetUsers') targetUsers!: Dropdown;

  constructor(
    broadcastService: BroadcasterService,
    authService: AuthenticationService,
    private fb: FormBuilder,
    private userService: UserService,
    private dayTasksService: DayTasksService,
    private toasterService: ToastService,
    private confirmationService: ConfirmationService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    super(broadcastService, authService);

    this.manageTask = config.data.task;
    if (!this.manageTask) {
      this.createTaskForm = this.fb.group({
        date: [new Date(), [Validators.required]],
        title: [null, [Validators.required]],
        description: [null],
        assignedTo: [null],
      });
      this.createTaskForm.get('assignedTo')!.valueChanges.subscribe((value) => {
        if (typeof value === 'string') {
          this.userService.getUsers(value, false).subscribe((data) => {
            this.filteredUsers = data;
            this.targetUsers.show();
          });
        }
      });
    } else {
      var assignedTo = undefined;
      if (this.manageTask.assignedTo) {
        assignedTo = {
          id: this.manageTask.assignedTo.appUserId,
          fullName: this.manageTask.assignedTo.fullName,
          profilePictureUrl: this.manageTask.assignedTo.profilePictureUrl,
        };
      }
      this.updateTaskForm = this.fb.group({
        title: [this.manageTask!.title, [Validators.required]],
        description: [this.manageTask.description],
        assignedTo: [assignedTo],
      });
      this.updateTaskForm.get('assignedTo')!.valueChanges.subscribe((value) => {
        if (typeof value === 'string') {
          this.userService.getUsers(value, false).subscribe((data) => {
            this.filteredUsers = data;
            this.targetUsers.show();
          });
        }
      });
    }
  }

  override ngOnInit() {
    super.ngOnInit();
  }
  createDayTask() {
    var form = this.createTaskForm.value;
    var request = {
      date: formatToLocalDateString(form.date),
      title: form.title,
      description: form.description,
      assignedTo: form.assignedTo?.id,
    } as CreateDayTaskRequest;
    this.dayTasksService.createTask(request).subscribe((data) => {
      this.toasterService.successToast('Task created!');
      this.ref.close({
        operation: 'create',
        date: form.date,
        task: {
          id: data.id,
          title: request.title,
          description: request.description,
          completed: false,
          assignedTo: request.assignedTo
            ? ({
                appUserId: request.assignedTo,
                fullName: form.assignedTo.fullName,
                profilePictureUrl: form.assignedTo.profilePictureUrl,
              } as AppUserBasics)
            : undefined,
          createdBy: {
            appUserId: this.authUser.appUserId,
            fullName: this.authUser.fullName,
            profilePictureUrl: this.authUser.profilePicUrl,
          } as AppUserBasics,
        } as DayTaskDto,
      });
    });
  }
  updateTask() {
    var form = this.updateTaskForm.value;
    var request = {
      title: form.title,
      description: form.description,
      assignedTo: form.assignedTo?.id,
    } as UpdateDayTaskDetailsRequest;
    this.dayTasksService
      .updateTaskDetails(this.manageTask!.id, request)
      .subscribe((data) => {
        this.toasterService.successToast('Task updated!');
        this.ref.close({
          operation: 'update',
          date: form.date,
          task: {
            ...this.manageTask,
            title: request.title,
            description: request.description,
            assignedTo: request.assignedTo
              ? ({
                  appUserId: request.assignedTo,
                  fullName: form.assignedTo.fullName,
                  profilePictureUrl: form.assignedTo.profilePictureUrl,
                } as AppUserBasics)
              : undefined,
          } as DayTaskDto,
        });
      });
  }
  deleteTask() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Delete task',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.dayTasksService.deleteTask(this.manageTask!.id).subscribe(() => {
          this.ref.close({
            operation: 'delete',
            taskId: this.manageTask!.id,
          });
        });
      },
      reject: () => {},
    });
  }
}
