import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReminderListItemDto } from '../../core/models/reminders/reminderListItemDto';
import { ReminderDetailsDto } from '../../core/models/reminders/reminderDetailsDto';
import { CreateReminderRequest } from '../../core/models/reminders/createReminderRequest';
import { CreateReminderResponse } from '../../core/models/reminders/createReminderResponse';
import { UpdateReminderRequest } from '../../core/models/reminders/updateReminderRequest';

@Injectable({
  providedIn: 'root',
})
export class RemindersService {
  constructor(private http: HttpClient) {}

  create(request: CreateReminderRequest): Observable<CreateReminderResponse> {
    return this.http.post<CreateReminderResponse>(`/reminders`, request);
  }
  getReminders(filterDate: string): Observable<ReminderListItemDto[]> {
    return this.http.get<ReminderListItemDto[]>(
      `/reminders?dateFilter=${filterDate}`
    );
  }
  checkReminderAvailibility(filterDate: string): Observable<boolean> {
    return this.http.get<boolean>(`/reminders/availablity?dateFilter=${filterDate}`);
  }
  toggleStatus(id: string): Observable<any> {
    return this.http.put<any>(`/reminders/${id}/toggle-status`, null);
  }
  updateReminder(
    reminderId: string,
    request: UpdateReminderRequest
  ): Observable<any> {
    return this.http.put<any>(`/reminders/${reminderId}`, request);
  }
  getReminderDetails(reminderId: string): Observable<ReminderDetailsDto> {
    return this.http.get<ReminderDetailsDto>(`/reminders/${reminderId}`);
  }
  deletedReminder(reminderId: string): Observable<any> {
    return this.http.delete<ReminderDetailsDto>(`/reminders/${reminderId}`);
  }
}
