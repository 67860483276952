import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitStringLengthNoChar',
})
export class LimitStringLengthNoCharPipe implements PipeTransform {
  transform(value: string, ...args: number[]): string {
    if (value && value.length > 0) {
      var noHtml = value.replace(/<[^>]*>/g, '');
      if (noHtml.length > args[0]) {
        return noHtml.substring(0, args[0]);
      } else {
        return noHtml;
      }
    } else {
      return '';
    }
  }
}
