import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuService } from './layout/app.menu.service';
import { LayoutService } from './layout/service/app.layout.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs';
import { BaseComponent } from './modules/core/components/base/base.component';
import { AuthenticationService } from './modules/authentication/services/authentication.service';
import { BroadcasterService } from './modules/core/services/broadcaster.service';
import { RemindersService } from './modules/reminders/services/reminders.service';
import { formatToLocalDateString } from './modules/core/utils/date-options';
import { SensorNotificationsService } from './modules/notifications/services/sensor-notifications.service';
import { GoogleAnalyticsService } from './modules/core/services/google-analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  title = 'teampal';
  modalVersion!: boolean;
  remindersAvailable: boolean = false;
  sensorNotificationsAvailable: boolean = false;

  constructor(
    broadcastService: BroadcasterService,
    authService: AuthenticationService,
    private swUpdate: SwUpdate,
    public layoutService: LayoutService,
    public menuService: MenuService,
    private remindersService: RemindersService,
    private notificationsService: SensorNotificationsService,
    private gaService:GoogleAnalyticsService
  ) {
    super(broadcastService, authService);
    this.layoutService.config.menuMode = 'slim';
    this.modalVersion = false;
    this.swUpdate.available.subscribe(() => {
      this.modalVersion = true;
    });
  }
  override ngOnInit(): void {
    super.ngOnInit();
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(
        filter(
          (evt: any): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
        ),
        map((evt: any) => {
          console.info(
            `currentVersion=[${evt.currentVersion} | latestVersion=[${evt.latestVersion}]`
          );
          this.modalVersion = true;
        })
      );
    }
    if (
      (this.authUser || this.authUser != null) &&
      this.authService.isUserAllowedForFeature('Reminders')
    ) {
      this.remindersService
        .checkReminderAvailibility(formatToLocalDateString(new Date()))
        .subscribe((data) => {
          this.remindersAvailable = data;
        });
    }
    if (
      (this.authUser || this.authUser != null) &&
      this.authService.isAdminsAndManger &&
      this.authService.isUserAllowedForFeature('Sensor Alerts')
    ) {
      this.notificationsService
        .isSensorNotificationsAvailable()
        .subscribe((data) => {
          this.sensorNotificationsAvailable = data;
        });
    }
    if(this.authUser){
      this.gaService.setGAUser(this.authUser)
    }
  }
  public updateVersion(): void {
    this.modalVersion = false;
    window.location.reload();
  }
  clearMessagereminderMessage() {
    this.remindersAvailable = false;
  }
  clearSensorNotificationsMessage() {
    this.sensorNotificationsAvailable = false;
  }
  override ngOnDestroy(): void {
    this.broadcastService.unsubscribe();
  }
}
