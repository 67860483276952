// Import the necessary function from date-fns
import { format } from 'date-fns';

export function formatToLocalDateString(date: Date): string {
  const dateFormat = 'M/d/yyyy';
  // Format the date
  return format(date, dateFormat);
}
export function formatToEUDateString(date: Date): string {
  const formatter = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
  return formatter.format(date);
}

export function convertTimeToLocalDate(timeString: string): Date {
  // Split the time string into hours and minutes
  const [time, period] = timeString.split(' ');
  const [hours, minutes] = time.split(':').map(Number);

  // Create a new Date object with the current date
  const date = new Date();

  // Set the hours and minutes based on the time string
  date.setHours(hours);
  date.setMinutes(minutes);

  // If the period is 'PM' and the hours are less than 12, add 12 hours
  if (period === 'PM' && hours < 12) {
    date.setHours(hours + 12);
  }

  return date;
}
export function convertTimeToUTCDate(timeString: string): Date {
  // Split the time string into hours and minutes
  const [time, period] = timeString.split(' ');
  const [hours, minutes] = time.split(':').map(Number);

  // Create a new Date object with the current date
  const date = new Date();

  // Set the hours and minutes based on the time string
  date.setUTCHours(hours);
  date.setUTCMinutes(minutes);

  // If the period is 'PM' and the hours are less than 12, add 12 hours
  if (period === 'PM' && hours <= 12) {
    date.setUTCHours(hours + 12);
  }

  return date;
}
export function convert24TimeToUTCDate(timeString: string): Date {
  // Split the time string into hours and minutes
  const [hours, minutes] = timeString.split(':').map(Number);
  // Create a new Date object with the current date
  const date = new Date();
  // Set the hours and minutes based on the time string
  date.setUTCHours(hours);
  date.setUTCMinutes(minutes);
  return date;
}

export function dateToAMPM(date: Date) {
  date = new Date(date);
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
}

// This function first creates Date objects from the input date strings (date1 and date2).
//Then, it compares the year, month, and day components of the two dates and returns a negative value if date1 is before date2,
//a positive value if date1 is after date2, and zero if they are equal.
export function compareDates(date1: Date, date2: Date) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  const d1Year = d1.getFullYear();
  const d2Year = d2.getFullYear();
  if (d1Year !== d2Year) {
    return d1Year - d2Year;
  }

  const d1Month = d1.getMonth();
  const d2Month = d2.getMonth();
  if (d1Month !== d2Month) {
    return d1Month - d2Month;
  }

  const d1Day = d1.getDate();
  const d2Day = d2.getDate();
  return d1Day - d2Day;
}

export function getTimeFromDate(date: Date): string {
  date = new Date(date);
  let hours = date.getHours();
  let minutes = date.getMinutes();

  let amPM = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12; // Convert to 12-hour format
  if (minutes == 0) {
    return `${hours} ${amPM}`;
  }
  return `${hours}:${minutes} ${amPM}`;
}
export function getTimeIn24FromDate(date: Date): string {
  var newDate = new Date(date);
  if (isNaN(newDate.getTime())) {
    return date.toString();
  }
  const hours = newDate.getHours().toString().padStart(2, '0'); // Ensure two digits
  const minutes = newDate.getMinutes().toString().padStart(2, '0'); // Ensure two digits
  return `${hours}:${minutes}`;
}

export function getTimeDifferenceInHours(fromTime: string, toTime: string) {
  //"12:00 AM" -"12:00 PM"
  // Convert time strings to Date objects
  const from = convertTimeToUTCDate(fromTime);
  const to = convertTimeToUTCDate(toTime);
  // Calculate the time difference in milliseconds
  const diffMs = to.getTime() - from.getTime();
  // Convert milliseconds to hours
  const diffHours = diffMs / (1000 * 60 * 60);
  return diffHours;
}
export function getTimeDifferenceInHoursAndMinutes(
  fromTime: string | Date,
  toTime: string | Date
): number {
  const time1 = new Date(fromTime);
  const time2 = new Date(toTime);
  const diffMilliseconds = Math.abs(time2.getTime() - time1.getTime());

  // Convert milliseconds to hours and minutes
  const diffHours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
  const diffMinutes = Math.floor(
    (diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
  );
  const minInHours = diffMinutes / 60;
  return diffHours + minInHours;
}

export function getStartAndEndOfMonth(date: Date) {
  // Get the year and month of the given date
  const year = date.getFullYear();
  const month = date.getMonth();

  // Create a new date for the start of the month
  const startDate = new Date(year, month, 1);

  // Create a new date for the end of the month
  // To get the end of the month, set the day to 0 of the next month
  const endDate = new Date(year, month + 1, 0);

  return { startDate, endDate };
}
