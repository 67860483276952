import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { ITokens } from '../constants/ITokens';
import { BroadcasterService } from './broadcaster.service';
import { BroadcastKeys } from '../constants/Broadcast';
import { Router } from '@angular/router';
import { ToastService } from './toast.service';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { AngularFirePerformance } from '@angular/fire/compat/performance';

@Injectable()
export class HTTPReqResInterceptor implements HttpInterceptor {
  constructor(
    @Inject(ITokens.API_URL) private _baseUrl: string,
    private broadcastService: BroadcasterService,
    private router: Router,
    private toster: ToastService,
    private authService: AuthenticationService,
    private perf: AngularFirePerformance
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.has('X-Skip-Interceptor')) {
      const headers = req.headers.delete('X-Skip-Interceptor');
      const clonedRequest = req.clone({ headers });
      return next.handle(clonedRequest);
    }

    this.broadcastService.broadcast(BroadcastKeys.HTTP_LOADING, true);
    const newReq = req.clone({
      url: this._baseUrl + req.url,
      headers: req.headers.set(
        'Authorization',
        `Bearer ${this.authService.authUser?.token}`
      ),
    });

    // Convert the trace promise to an observable using `from()`
    return from(this.perf.trace(`http-request-${req.url}`)).pipe(
      switchMap((trace) => {
        trace.start(); // Start the performance trace
        if (this.authService.authUser) {
          trace.putAttribute(
            'teampalUserId',
            this.authService.authUser.teamPalUserId
          );
          trace.putAttribute('appUserId', this.authService.authUser.appUserId!);
          trace.putAttribute('tenantId', this.authService.authUser.tenantId);
          trace.putAttribute('branchId', this.authService.authUser.branchId);
        }
        return next.handle(newReq).pipe(
          tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              // Add custom attributes for successful response
              trace.putAttribute('status', event.status.toString());
            }
          }),
          catchError((err) => {
            // Add custom attributes for errors
            if (err instanceof HttpErrorResponse) {
              trace.putAttribute('error', err.status.toString());
            }
            return this.handleError(newReq, next, err);
          }),
          finalize(() => {
            trace.stop(); // Stop the trace when the request finishes
            this.broadcastService.broadcast(BroadcastKeys.HTTP_LOADING, false);
          })
        );
      })
    );
  }

  handleError(newRequest: HttpRequest<any>, next: HttpHandler, err: any) {
    if (err instanceof HttpErrorResponse && err.status === 401) {
      this.toster.errorToast('Error', err.error.Errors.Error);
      this.authService.setAuthUser(null);
      this.router.navigate(['/auth/login']);
    } else if (err instanceof HttpErrorResponse && err.status === 410) {
      this.authService.setAuthUser(null);
      this.toster.errorToast('Error', 'Company is disabled.');
      this.router.navigate(['/auth/error']);
    } else if (err instanceof HttpErrorResponse && err.status === 403) {
      this.toster.errorToast('Error', err.error.Errors.Error);
      this.router.navigate(['/tenant']);
    } else {
      if (err.error.errors) {
        for (var key in err.error.errors) {
          if (err.error.errors.hasOwnProperty(key)) {
            this.toster.errorToast(key, err.error.errors[key]);
          }
        }
      } else if (err.error.Errors) {
        for (var key in err.error.Errors) {
          if (err.error.Errors.hasOwnProperty(key)) {
            this.toster.errorToast(key, err.error.Errors[key]);
          }
        }
      } else {
        this.toster.errorToast('Error', 'Hmm.. Something went wrong!');
      }
    }
    return throwError(err);
  }
}
