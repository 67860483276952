import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-empty-message',
  templateUrl: './table-empty-message.component.html',
  styleUrls: ['./table-empty-message.component.scss']
})
export class TableEmptyMessageComponent {
  @Input() emptyMessage:string="No data!"
  @Input() icon?:string;
}
