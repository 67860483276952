import { Component } from '@angular/core';

@Component({
  selector: 'app-message-participant-loading',
  templateUrl: './message-participant-loading.component.html',
  styleUrls: ['./message-participant-loading.component.scss']
})
export class MessageParticipantLoadingComponent {

}
