import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private analytics: AngularFireAnalytics) {}

  handleError(error: any) {
   
    // Log error to Firebase Analytics
    this.analytics.logEvent('console_error', {
      description: error?.message || 'Unknown error',
      fatal: false,
    });

    // Log to the browser console (optional)
    console.error('Error from global error handler', error);

  }
}
