import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '../../authentication/services/authentication.service';

@Directive({
  selector: '[appHideForOwner]',
})
export class HideForOwnerDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthenticationService
  ) {}

  @Input() set appHideForOwner(userId: string) {
    if (this.authService.authUser!.appUserId == userId) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
