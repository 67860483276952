<ul
  *ngIf="tasksList.length > 0 && !isDayTasksDataLoading; else noData"
  class="list-none p-0 m-0 h-full"
>
  <li
    *ngFor="let task of tasksList"
    class="flex justify-content-between flex-wrap grid align-items-start p-2 border-bottom-1 surface-border"
  >
    <div
      class="flex align-items-center col-8 lg:col-4 flex-order-1 lg:flex-order-1"
    >
      <span>
        <p-checkbox
          [value]="task"
          [(ngModel)]="selectedTasks"
          [inputId]="task.id"
          name="group"
          (onChange)="checkBoxChange($event, task.id)"
        ></p-checkbox>
      </span>
      <label
        for="task.id"
        style="word-break: break-word"
        class="font-semibold ml-2"
        [ngClass]="{
          'line-through': task.completed
        }"
        (click)="openManageTask('update', task)"
        >{{ task.title }}</label
      >
    </div>
    <div
      (click)="openManageTask('update', task)"
      class="col:12 lg:col-6 flex-order-3 lg:flex-order-2"
    >
      <p class="text-base text-start ml-6 lg:ml-0">
        {{ task.description | emptyValue }}
      </p>
    </div>
    <div
      (click)="openManageTask('update', task)"
      *ngIf="task.completed"
      class="flex align-items-center justify-content-center col-4 lg:col-2 flex-order-2 lg:flex-order-3"
    >
      <p class="text-sm text-center" *ngIf="task.completedBy">
        {{ task.completedBy.fullName }}
      </p>
    </div>
    <div
      *ngIf="!task.completed"
      class="flex align-items-center justify-content-center col-4 lg:col-2 flex-order-2 lg:flex-order-3"
    >
      <p class="text-sm text-center"><span *ngIf="task.assignedTo">{{task.assignedTo.fullName+' / '}}</span>Not completed</p>
    </div>
  </li>
</ul>
<ng-template #noData>
  <div *ngIf="!isDayTasksDataLoading; else showLoading" [class]="isPage?'empty-content-container':'w-full flex justify-content-center h-5rem align-items-center'">
    <p class="text-lg text-center">No tasks have been added.</p>
  </div>
  <ng-template #showLoading>
    <app-routine-list-loading></app-routine-list-loading>
  </ng-template>
</ng-template>
