import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timein24FromTime',
})
export class Timein24FromTimePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    const [time, period] = value.split(' ');
    let [hours, minutes] = time.split(':');

    if (period.toLowerCase() === 'pm') {
      hours = String(parseInt(hours, 10) + 12);
    }

    return `${hours.padStart(2, '0')}:00`;
  }
}
