import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedResponse } from '../../core/models/paginatedResponse';
import { SensorNotificationDto } from '../../core/models/notifications/sensors/sensorNotificationDto';
import { UpdateSensorNotificationRequest } from '../../core/models/notifications/sensors/updateSensorNotificationRequest';

@Injectable({
  providedIn: 'root',
})
export class SensorNotificationsService {
  constructor(private http: HttpClient) {}

  getSensorNotifications(
    pageNumber: number
  ): Observable<PaginatedResponse<SensorNotificationDto>> {
    return this.http.get<PaginatedResponse<SensorNotificationDto>>(
      `/notifications/sensors?pageNumber=${pageNumber}`
    );
  }
  isSensorNotificationsAvailable(): Observable<boolean> {
    return this.http.get<boolean>(`/notifications/sensors/available`);
  }
  updateSummeryAlertDetails(
    summeryId: string,
    request: UpdateSensorNotificationRequest
  ): Observable<any> {
    return this.http.put<any>(
      `/notifications/sensors?alertSummeryId=${summeryId}`,
      request
    );
  }
}
