import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyValue'
})
export class EmptyValuePipe implements PipeTransform {

  transform(value: string|undefined|null, ...args: unknown[]): string {
    return (value==undefined || value==null)?'-':value;
  }

}
