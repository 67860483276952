<ul class="list-none p-0 m-0 routine-list">
  <li
    *ngFor="let task of [1, 2, 3]"
    class="flex justify-content-between flex-wrap grid align-items-center p-2 border-bottom-1 surface-border"
  >
    <div
      class="flex align-items-center col-10 lg:col-4 flex-order-1 lg:flex-order-1"
    >
      <p-skeleton size="2rem" styleClass="mr-2"></p-skeleton>
      <p-skeleton
        width="10rem"
        styleClass="mb-2"
        borderRadius="16px"
      ></p-skeleton>
    </div>
    <div class="col:12 lg:col-6 flex-order-3 lg:flex-order-2">
      <p-skeleton
        width="15rem"
        styleClass="mb-2"
        borderRadius="16px"
      ></p-skeleton>
    </div>
    <div
      class="flex align-items-center justify-content-end col-2 flex-order-2 lg:flex-order-3"
    >
      <p-avatarGroup>
        <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
      </p-avatarGroup>
    </div>
  </li>
</ul>
