<ngx-loading-bar
  [includeSpinner]="false"
  height="5px"
  color="#1C1C1C"
></ngx-loading-bar>
<p
  pMessage
  class="p-message-warn p-message p-2 flex justify-content-center align-items-center h-3rem"
  [routerLink]="['/notifications']"
  *ngIf="sensorNotificationsAvailable"
>
  <span class="mr-2">You have sensor alerts that require your attention.</span>
  <p-button
    styleClass="p-0"
    [size]="'small'"
    icon="pi pi-times"
    [rounded]="true"
    (onClick)="clearSensorNotificationsMessage()"
    [text]="true"
    severity="danger "
  ></p-button>
</p>
<p
  pMessage
  class="p-message-info p-message p-2 flex justify-content-center align-items-center h-3rem"
  [routerLink]="['/reminders']"
  *ngIf="remindersAvailable"
>
  <span class="mr-2">Click here to see today's reminders</span>
  <p-button
    styleClass="p-0"
    [size]="'small'"
    icon="pi pi-times"
    [rounded]="true"
    (onClick)="clearMessagereminderMessage()"
    [text]="true"
    severity="danger "
  ></p-button>
</p>
<p-toast></p-toast>
<router-outlet></router-outlet>
<p-sidebar
  id="update-bar"
  [(visible)]="modalVersion"
  position="bottom"
  [showCloseIcon]="false"
  [dismissible]="false"
>
  <div class="flex justify-content-between">
    <p>You have a new update!</p>
    <p-button
      (click)="updateVersion()"
      styleClass="p-button-sm p-button-success"
      class="p-button-success"
      >Update</p-button
    >
  </div>
</p-sidebar>
