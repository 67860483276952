import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputSwitchOnChangeEvent } from 'primeng/inputswitch';

@Component({
  selector: 'app-switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss'],
})
export class SwitchButtonComponent {
  @Input() checked!: boolean;
  @Output() valueChanged = new EventEmitter<boolean>();

  changed(event: InputSwitchOnChangeEvent) {
    this.valueChanged.emit(event.checked);
  }
}
