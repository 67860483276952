<div class="w-full p-4 lg:p-8 flex flex-column">
    <div class="w-full flex flex-column lg:flex-row justify-content-center lg:justify-content-between mb-4">
        <div class="mb-4 lg:mb-0">
            <p class="text-4xl text-900 font-bold mb-0 text-primary">Settings</p>
            <p class="hidden lg:block">Your account settings</p>
        </div>
        <p class="text-xl text-900  lg:text-2xl font-bold mb-0">Hi, {{getAuthUserFullName()}}</p>
    </div>
    <div class="w-full flex flex-column lg:flex-row justify-content-between">
        <div class="flex flex-column w-full lg:w-3 mb-3 lg:mb-0">
            <div class="w-full" *ngIf="isTenantAuthenticated()">
                <p-button styleClass="w-full settings-menu-button text-left text-800" [routerLink]="['/']" label="App" size="small" [link]="true"></p-button>
            </div>
            <div class="w-full flex flex-column">
                <p-button styleClass="w-full settings-menu-button text-left text-800" [routerLink]="['companies']" [routerLinkActive]="['active']" label="Companies" size="small" [link]="true"></p-button>
                <div class="ml-4">
                    <p-button styleClass="w-full settings-menu-button text-left " [routerLink]="['create-company']" [routerLinkActive]="['active']" label="Create company" icon="pi pi-plus"  [link]="true" iconPos="right" size="small"  [rounded]="true" severity="success"></p-button>
                </div>
            </div>
            <div class="w-full">
                <p-button  styleClass="w-full settings-menu-button text-left text-800" [routerLink]="['profile']" [routerLinkActive]="['active']" label="Profile" size="small" [link]="true"></p-button>
            </div>
            <div class="w-full">
                <p-button (click)="signOut()" styleClass="w-full settings-menu-button text-left text-800"  label="Logout" size="small" [link]="true"></p-button>
            </div>
        </div>
        <div class="flex flex-column w-full lg:w-9">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<p-confirmDialog></p-confirmDialog>