<div class="layout-topbar">
  <div class="topbar-start">
    <button
      #menubutton
      type="button"
      class="topbar-menubutton p-link p-trigger hidden"
      (click)="onMenuButtonClick()"
    >
      <i class="pi pi-bars"></i>
    </button>

    <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
  </div>

  <div class="topbar-end">
    <ul class="topbar-menu">
      <li class="ml-3" *appShowForUser="['superadmin']">
        <p-button
          [badge]="getProductsCount()"
          [routerLink]="['/shop/cart']"
          icon="pi pi-shopping-cart"
          [text]="true"
          [rounded]="true"
          severity="secondary"
          styleClass="flex-shrink-0"
          (click)="onConfigButtonClick()"
        />
      </li>
      <li class="topbar-profile">
        <button type="button" class="p-link" (click)="onProfileButtonClick()">
          <img [src]="profileImage ?? 'assets/images/user.png'" alt="Profile" />
        </button>
      </li>
    </ul>
  </div>
</div>
