import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeAMPM',
})
export class RemoveAMPMPipe implements PipeTransform {
  transform(value: string | null): string {
    if (value == null) {
      return '';
    }
    return value.replace(/\s[AP]M$/, '');
  }
}
