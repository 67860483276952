import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './components/base/base.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';


@NgModule({
  declarations: [
    BaseComponent
  ],
  imports: [
    CommonModule,
    MatSnackBarModule
  ]
})
export class CoreModule { 
    /* make sure CoreModule is imported only by the AppModule and noone else */
    constructor(@Optional() @SkipSelf() presentInParent: CoreModule) {
      if (presentInParent) {
        throw new Error('CoreModule is already loaded. Import only in AppModule');
      }
    }
}
