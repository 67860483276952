<div
  class="layout-sidebar"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <div class="sidebar-header">
    <a
      [routerLink]="['/tenant']"
      class="app-logo flex justify-content-center align-items-center"
    >
      <i class="pi pi-building text-primary text-4xl mb-4"></i>
    </a>

    <button
      class="layout-sidebar-anchor p-link z-2 mb-2"
      type="button"
      (click)="anchor()"
    ></button>
  </div>

  <div #menuContainer class="layout-menu-container">
    <app-menu [teampalFeatures]="teampalFeatures"></app-menu>
  </div>
</div>
