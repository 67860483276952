<div class="w-full flex flex-column">
  <form
    *ngIf="!this.manageTask; else updatetask"
    class="grid"
    [formGroup]="createTaskForm"
    (ngSubmit)="createDayTask()"
  >
    <div class="col-12">
      <div class="flex flex-column p-fluid">
        <div class="mb-4">
          <label for="title" class="text-900 font-semibold">Title</label>
          <input
            type="text"
            pInputText
            placeholder="Title"
            formControlName="title"
          />
        </div>
        <div class="mb-4">
          <label for="description" class="text-900 font-semibold"
            >Description</label
          >
          <textarea
            rows="5"
            lass="w-12"
            pInputTextarea
            placeholder="Description"
            formControlName="description"
          >
          </textarea>
        </div>
        <div *appShowForUser="['admin', 'superadmin', 'manager']" class="mb-4">
          <label for="assignedTo" class="text-900 font-semibold"
            >Assign to</label
          >
          <p-dropdown
            formControlName="assignedTo"
            [options]="filteredUsers"
            optionLabel="fullName"
            placeholder="Search user"
            [editable]="true"
            #targetUsers
          ></p-dropdown>
        </div>
        <div class="mb-4">
          <label for="date" class="text-900 font-semibold">Date</label>
          <p-calendar
            appendTo="body"
            dateFormat="yy-mm-dd"
            [showTime]="false"
            inputId="date"
            formControlName="date"
            placeholder="Date"
          ></p-calendar>
        </div>

        <div class="col-12 flex justify-content-end mt-4">
          <button
            pButton
            pRipple
            class="p-button-success w-8rem"
            icon="pi pi-check"
            label="Create"
            type="submit"
            [disabled]="isLoading || !createTaskForm.valid"
          ></button>
        </div>
      </div>
    </div>
  </form>
  <ng-template #updatetask>
    <form class="grid" [formGroup]="updateTaskForm" (ngSubmit)="updateTask()">
      <div class="col-12">
        <div class="flex flex-column p-fluid">
          <div class="mb-4">
            <label for="title" class="text-900 font-semibold">Title</label>
            <input
              type="text"
              pInputText
              placeholder="Title"
              formControlName="title"
            />
          </div>
          <div class="mb-4">
            <label for="description" class="text-900 font-semibold"
              >Description</label
            >
            <textarea
              rows="5"
              lass="w-12"
              pInputTextarea
              placeholder="Description"
              formControlName="description"
            >
            </textarea>
          </div>
          <div
            *appShowForUser="['admin', 'superadmin', 'manager']"
            class="mb-4"
          >
            <label for="assignedTo" class="text-900 font-semibold"
              >Assign to</label
            >
            <p-dropdown
              formControlName="assignedTo"
              [options]="filteredUsers"
              optionLabel="fullName"
              placeholder="Search user"
              [editable]="true"
              #targetUsers
            ></p-dropdown>
          </div>

          <div class="col-12 flex justify-content-end mt-4">
            <span class="mr-2">
              <button
                pButton
                pRipple
                class="p-button-danger w-8rem"
                label="Delete"
                type="button"
                (click)="deleteTask()"
                [disabled]="isLoading"
              ></button>
            </span>
            <button
              pButton
              pRipple
              class="p-button-warning w-8rem"
              label="Update"
              type="submit"
              [disabled]="isLoading || !updateTaskForm.valid"
            ></button>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
</div>