import { Input, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { BaseComponent } from '../modules/core/components/base/base.component';
import { BroadcasterService } from '../modules/core/services/broadcaster.service';
import { AuthenticationService } from '../modules/authentication/services/authentication.service';
import { RouteDescription } from '../modules/core/constants/routeDescriptions';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent extends BaseComponent implements OnInit {
  @Input() teampalFeatures!: RouteDescription[];

  constructor(
    authService: AuthenticationService,
    broadcastService: BroadcasterService
  ) {
    super(broadcastService, authService);
  }
  override ngOnInit() {
    super.ngOnInit();
  }
}
