import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DayTaskDto } from '../../core/models/day-tasks/dayTaskDto';
import { Observable } from 'rxjs';
import { CreateDayTaskRequest } from '../../core/models/day-tasks/createDayTaskRequest';
import { CreateDayTaskResponse } from '../../core/models/day-tasks/createDayTaskResponse';
import { UpdateDayTaskStatusRequest } from '../../core/models/day-tasks/updateDayTaskStatusRequest';
import { UpdateDayTaskDetailsRequest } from '../../core/models/day-tasks/updateDayTaskDetailsRequest';

@Injectable({
  providedIn: 'root',
})
export class DayTasksService {
  constructor(private http: HttpClient) {}

  getDayTasks(filterDate: string): Observable<DayTaskDto[]> {
    return this.http.get<DayTaskDto[]>(`/day-tasks?filterDate=${filterDate}`);
  }
  createTask(request: CreateDayTaskRequest): Observable<CreateDayTaskResponse> {
    return this.http.post<CreateDayTaskResponse>(`/day-tasks`, request);
  }
  updateTaskStatus(
    taskId: string,
    request: UpdateDayTaskStatusRequest
  ): Observable<any> {
    return this.http.put<any>(`/day-tasks/${taskId}/update-status`, request);
  }
  updateTaskDetails(
    taskId: string,
    request: UpdateDayTaskDetailsRequest
  ): Observable<any> {
    return this.http.put<any>(`/day-tasks/${taskId}`, request);
  }
  deleteTask(taskId: string): Observable<any> {
    return this.http.delete<any>(`/day-tasks/${taskId}`);
  }
}
