import { Component } from '@angular/core';

@Component({
  selector: 'app-routine-list-loading',
  templateUrl: './routine-list-loading.component.html',
  styleUrls: ['./routine-list-loading.component.scss']
})
export class RoutineListLoadingComponent {

}
