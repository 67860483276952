<div
  class="flex flex-nowrap justify-content-between card align-items-start border-1 surface-border border-round p-2 cursor-pointer select-none transition-colors transition-duration-150"
  tabindex="0"
>
  <div class="flex align-items-center w-9 text-container">
    <div class="relative mr-2">
      <div class="w-2rem h-2rem border-circle shadow-1">
        <p-skeleton shape="circle" size="2rem" styleClass="mr-2" />
      </div>
    </div>
    <div class="flex-column flex">
      <p-skeleton width="6rem" styleClass="mb-2" borderRadius="16px" />
      <span
        class="block text-overflow-ellipsis overflow-hidden white-space-nowrap w-10rem text-sm"
        class="text-600"
      >
        <p-skeleton width="10rem" styleClass="mb-2"
      /></span>
    </div>
  </div>
  <span class="last-text-date ml-auto text-700"
    ><p-skeleton width="5rem" borderRadius="16px"
  /></span>
</div>
