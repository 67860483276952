import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundNumber',
})
export class RoundNumberPipe implements PipeTransform {
  transform(value: number, decimalPlaces: number = 0): string {
    const roundedValue = Number(value.toFixed(decimalPlaces));
    return roundedValue % 1 === 0
      ? String(roundedValue)
      : roundedValue.toFixed(decimalPlaces);
  }
}
