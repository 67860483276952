import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthUser } from '../../models/users/authUser';
import { BroadcasterService } from '../../services/broadcaster.service';
import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { BroadcastKeys } from '../../constants/Broadcast';
import { USerRole } from '../../models/users/userRole';
import { userRolesList } from '../../constants/UserRoles';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  userRoles: USerRole[] = userRolesList;

  constructor(
    protected broadcastService: BroadcasterService,
    protected authService: AuthenticationService
  ) {}
  get authUser(): AuthUser {
    return this.authService.authUser!;
  }
  ngOnInit(): void {
    this.broadcastService
      .listen(BroadcastKeys.HTTP_LOADING)
      .subscribe((loading) => {
        this.isLoading = loading as boolean;
      });
  }
  isTenantAuthenticated() {
    return (
      this.authUser &&
      this.authUser.tenantId != null &&
      this.authUser.branchId != null
    );
  }
  getAuthUserFullName() {
    return this.authUser?.fullName;
  }

  ngOnDestroy(): void {}
}
