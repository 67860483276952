import { Injectable } from '@angular/core';
import { CartDetails } from '../../core/models/shop/cart/cartDetails ';
import { CartProduct } from '../../core/models/shop/cart/cartProduct ';
import { ShopProductsServiceService } from './shop-products-service.service';
import { catchError, map, of } from 'rxjs';
import { ShopSettingsDto } from '../../core/models/shop/products/shopSettingsDto';
import { AuthenticationService } from '../../authentication/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ShopCartService {
  private localStorageKey = 'shoppingCart';

  constructor(
    private shopProductsService: ShopProductsServiceService,
    authService: AuthenticationService
  ) {
    if (authService.authUserIsSuperAdmin) {
      this.initializeCartWithSettings();
    }
  }

  private getCartDetails(): CartDetails {
    const cart = localStorage.getItem(this.localStorageKey);
    return cart ? JSON.parse(cart) : this.initializeCart();
  }

  private saveCartDetails(cartDetails: CartDetails): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(cartDetails));
  }

  private initializeCart(): CartDetails {
    return {
      subTotal: 0,
      vat: 0,
      shippingFee: 0,
      calculatedVat: 0,
      calculatedShippingFee: 0,
      total: 0,
      products: [],
    };
  }

  private initializeCartWithSettings(): void {
    const cart = this.getCartDetails();
    if (cart.vat === 0 || cart.shippingFee === 0) {
      this.shopProductsService
        .getShopSettings()
        .pipe(
          catchError(() => of({ taxPrecentage: 0, shippingFee: 0 })), // Default values in case of error
          map((settings: ShopSettingsDto) => {
            return this.saveCartWithSettings(settings,cart);
          })
        )
        .subscribe();
    }
  }

  public updateShopSettings(): void {
    const cart = this.getCartDetails();
    this.shopProductsService
    .getShopSettings()
    .pipe(
      catchError(() => of({ taxPrecentage: 0, shippingFee: 0 })), // Default values in case of error
      map((settings: ShopSettingsDto) => {
        return this.saveCartWithSettings(settings,cart);
      })
    )
    .subscribe();
  }
  private saveCartWithSettings(settings: ShopSettingsDto,cart:CartDetails){
    cart.vat = settings.taxPrecentage;
    cart.shippingFee = settings.shippingFee;
    cart.calculatedShippingFee = settings.shippingFee;
    this.updateCart(cart);
    return cart
  }

  public getCart(): CartDetails {
    return this.getCartDetails();
  }

  addProduct(product: CartProduct): void {
    const cart = this.getCartDetails();
    const existingProduct = cart.products.find(
      (p) => p.productId === product.productId
    );

    if (existingProduct) {
      existingProduct.quantity += product.quantity;
    } else {
      cart.products.push(product);
    }

    this.updateCart(cart);
  }

  removeProduct(productId: string): void {
    const cart = this.getCartDetails();
    cart.products = cart.products.filter((p) => p.productId !== productId);

    this.updateCart(cart);
  }

  updateProductQuantity(productId: string, quantity: number): void {
    const cart = this.getCartDetails();
    const product = cart.products.find((p) => p.productId === productId);

    if (product) {
      product.quantity = quantity;
      this.updateCart(cart);
    }
  }

  clearCart(): void {
    this.saveCartDetails(this.initializeCart());
  }

  private updateCart(cart: CartDetails): void {
    cart.subTotal = this.calculateSubTotal(cart.products);
    cart.calculatedVat = this.calculateVAT(cart.subTotal, cart.vat);
    cart.total = cart.subTotal + cart.calculatedVat + cart.calculatedShippingFee;

    this.saveCartDetails(cart);
  }

  private calculateSubTotal(products: CartProduct[]): number {
    return products.reduce(
      (sum, product) => sum + product.quantity * product.price,
      0
    );
  }

  private calculateVAT(subTotal: number, taxPercentage: number): number {
    return subTotal * (taxPercentage / 100);
  }

  updateShippingFeeAndVAT(shippingFee: number, vat: number): void {
    const cart = this.getCartDetails();
    cart.shippingFee = shippingFee;
    cart.vat = vat;
    this.updateCart(cart);
  }
  getProductCount(): number {
    const cart = this.getCartDetails();
    return cart.products.length
  }
  getProductQuantity(productId: string): number {
    const cart = this.getCartDetails();
    const product = cart.products.find(p => p.productId === productId);
    return product ? product.quantity : 0;
  }
  productExists(productId: string): boolean {
    const cart = this.getCartDetails();
    return cart.products.some(p => p.productId === productId);
  }
}
