import { Injectable } from '@angular/core';
import { AuthUser } from '../../core/models/users/authUser';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastService } from '../../core/services/toast.service';
import { LoginRequest } from '../../core/models/authentication/loginRequest';
import { LoginResponse } from '../../core/models/authentication/loginResponse';
import { CreateUserRequest } from '../../core/models/authentication/createUserRequest';
import { CreateUserResponse } from '../../core/models/authentication/createUserResponse';
import { Observable } from 'rxjs';
import { UpdatePasswordRequest } from '../../core/models/authentication/updatePasswordRequest';
import { UploadProfilePictureResponse } from '../../core/models/authentication/uploadProfilePictureResponse';
import { UploadProfilePictureRequest } from '../../core/models/authentication/uploadProfilePictureRequest';
import { ResetPasswordUserDetails } from '../../core/models/authentication/resetPasswordUserDetails';
import { ResetPasswordRequest } from '../../core/models/authentication/resetPasswordRequest';
import { ForgetPasswordRequest } from '../../core/models/authentication/forgetPasswordRequest';
import { NotificationsService } from '../../users/services/notifications.service';
import {
  FeatureLabel,
  RouteDescription,
  featurePriorities,
  routeDescriptions,
} from '../../core/constants/routeDescriptions';
import { TeampalFeature } from '../../core/models/teampal-features/teampalFeature';
import { SignupRequest } from '../../core/models/authentication/signupRequest';
import { SignupResponse } from '../../core/models/authentication/signupResponse';
import { SwitchCompanyRequest } from '../../core/models/authentication/switchCompanyRequest';
import { UpdateProfileDetailsRequest } from '../../core/models/authentication/updateProfileDetailsRequest';
import { GoogleAnalyticsService } from '../../core/services/google-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  authUser: AuthUser | null = this.getAuthUser();
  isAdminsAndManger: boolean = this.getIsAdminAndManger();
  teampalFeatures: RouteDescription[] = [];
  constructor(
    private http: HttpClient,
    private router: Router,
    private toaster: ToastService,
    private notificationService: NotificationsService,
    private gaService:GoogleAnalyticsService
  ) {}

  get authUserIsSuperAdmin(): boolean {
    return this.authUser?.userRoles.find((r) => r == 'superadmin') != null;
  }
  get authUserIsAdmins(): boolean {
    return (
      this.authUser?.userRoles.find(
        (r) => r == 'admin' || r == 'superadmin' || r == 'manager'
      ) != null
    );
  }

  loginUser(request: LoginRequest) {
    this.http.post<LoginResponse>('/auth/login', request).subscribe({
      next: (loginDetails) => {
        this.setAuthUser(loginDetails);
        this.toaster.successToast('Login success.');
        this.router.navigate(['/overviews']);
      },
    });
  }
  switchCompany(request: SwitchCompanyRequest,complete?: () => void) {
    this.http.post<LoginResponse>('/auth/login/switch', request).subscribe({
      next: (loginDetails) => {
        this.setAuthUser(loginDetails);
        this.toaster.successToast('Login success.');
        this.router.navigate(['/overviews']);
      },
      complete:()=>{
        if(complete){
          complete()
        }
      }
    });
  }
  signUp(request: SignupRequest) {
    this.http.post<SignupResponse>('/auth/register', request).subscribe({
      next: (loginDetails) => {
        this.setAuthUser({
          ...loginDetails,
          userRoles: [],
          appUserId: undefined,
          profilePicUrl: undefined,
          tenantId: undefined,
          tenantName: undefined,
          tenantLogoUrl: undefined,
          branchId: undefined,
          branchCode: undefined,
          features: [],
        });
        this.toaster.successToast('Account created!');
        this.router.navigate(['/settings']);
      },
    });
  }
  resetPassword(request: ResetPasswordRequest) {
    return this.http
      .put<LoginResponse>(`/auth/password/reset`, request)
      .subscribe({
        next: (loginDetails) => {
          this.setAuthUser(loginDetails);
          this.toaster.successToast('Password has been reset.');
          this.router.navigate(["/overviews"]);
        },
      });
  }
  forgetPassword(request: ForgetPasswordRequest) {
    return this.http.post<any>(`/auth/password/forget-password`, request);
  }

  getProfile(userId: string): Observable<ResetPasswordUserDetails> {
    return this.http.get<ResetPasswordUserDetails>(`/auth/users/${userId}`);
  }
  createUser(request: CreateUserRequest): Observable<CreateUserResponse> {
    return this.http.post<CreateUserResponse>('/users', request);
  }

  uploadProfilePicture(
    request: UploadProfilePictureRequest
  ): Observable<UploadProfilePictureResponse> {
    var data = new FormData();
    data.append('file', request.file);
    return this.http.put<UploadProfilePictureResponse>('/auth/avatar', data);
  }
  updateProfilePicture(profileUrl: string) {
    var authUser = this.getAuthUser();
    authUser!.profilePicUrl = profileUrl;
    this.setAuthUser(authUser);
  }
  updatePassword(passwordRequest: UpdatePasswordRequest): Observable<any> {
    return this.http.put('/auth/password', passwordRequest);
  }
  updateProfileDetails(request: UpdateProfileDetailsRequest): Observable<any> {
    return this.http.put('/auth/profile', request);
  }

  setAuthUser(loginDetails: LoginResponse | null) {
    if (loginDetails == null) {
      localStorage.removeItem('authUser');
      this.authUser = null;
      this.gaService.setGAUser(undefined)
      return;
    }
    this.authUser = loginDetails as AuthUser;
    this.gaService.setGAUser(this.authUser)
    localStorage.setItem('authUser', JSON.stringify(this.authUser));
  }
  private getAuthUser(): AuthUser | null {
    var authUser = localStorage.getItem('authUser');
    return authUser ? JSON.parse(authUser) : null;
  }
  getIsAdminAndManger() {
    var isManagerialType =
      this.authUser != null &&
      (this.authUser.userRoles.find((r) => r == 'admin') !=null||
        this.authUser.userRoles.find((r) => r == 'superadmin') !=null ||
        this.authUser.userRoles.find((r) => r == 'manager') !=null);
    return isManagerialType
  }
  private getTenantFeatures(): TeampalFeature[] | undefined {
    return this.getAuthUser()?.features;
  }
  isFeatureMenuItemAllowed(
    label: FeatureLabel,
    features: TeampalFeature[]
  ): boolean {
    var featureId = this.getFeatureIdForFeature(label);
    if (featureId == null) {
      return true;
    }
    if (features.find((f) => f.id == featureId) != null) {
      return true;
    }
    return false;
  }
  getFeatureIdForFeature(label: FeatureLabel): number | null {
    var featureId = null;
    switch (label) {
      case 'Routines':
        featureId = 1;
        break;
      case 'Tasks':
        featureId = 2;
        break;
      case 'Posts':
        featureId = 3;
        break;
      case 'Messages':
        featureId = 4;
        break;
      case 'Discussions':
        featureId = 5;
        break;
      case 'Files':
        featureId = 6;
        break;
      case 'Reminders':
        featureId = 7;
        break;
      case 'Quizzes':
        featureId = 8;
        break;
      case 'Sensors':
        featureId = 9;
        break;
      case 'Sensor Alerts':
        featureId = 10;
        break;
      case 'Notifications':
        featureId = 11;
        break;
      case 'Dashboards':
        featureId = 12;
        break;
      case 'Group Messages':
        featureId = 13;
        break;
      case 'Push Notifications':
        featureId = 14;
        break;
      case 'Shifts':
        featureId = 15;
        break;
      case 'Day Tasks':
        featureId = 16;
        break;
      case 'Forms':
        featureId = 17;
        break;
      default:
        featureId = null;
        break;
    }
    return featureId;
  }

  getFeatureForRoute(url: string): RouteDescription | undefined {
    return routeDescriptions().find(
      (rd) => rd.routerLink[0].split('/')[1] == url
    );
  }
  isRouteAccessible(url: string): boolean {
    var rd = this.getFeatureForRoute(url);
    if (!rd) {
      return true;
    }
    var features = this.getTenantFeatures();
    if (!features) {
      return false;
    }
    return this.isFeatureMenuItemAllowed(rd.label, features);
  }

  showFeature(label: FeatureLabel): boolean {
    var features = this.getTenantFeatures();
    if (!features) {
      return false;
    }
    var featureId = this.getFeatureIdForFeature(label);
    if (featureId == null) {
      return true;
    }
    if (features.find((f) => f.id == featureId) != null) {
      return true;
    }
    return false;
  }

  filterFeatures(routeDescriptions: RouteDescription[]) {
    var features = this.getTenantFeatures();
    if (!features) {
      return [];
    }
    return routeDescriptions.filter((rd) => {
      return this.isFeatureMenuItemAllowed(rd.label, features!);
    });
  }
  isUserAllowedForFeature(label: FeatureLabel): boolean {
    return (
      this.authUser?.features.find(
        (f) => f.id == this.getFeatureIdForFeature(label)
      ) != null
    );
  }
  getPriorityRouteDescription(): RouteDescription | null {
    for (let index = 0; index < featurePriorities.length; index++) {
      const element = featurePriorities[index];
      var isAllowed = this.isUserAllowedForFeature(element);
      if (isAllowed) {
        return routeDescriptions().find((rd) => rd.label == element)!;
      }
    }
    return null;
  }
  getPriorityRoute(): string {
    var pR = this.getPriorityRouteDescription();
    if (pR != null) {
      return pR.routerLink[0];
    }
    return '/tenant';
  }
  logoutUser() {
    this.setAuthUser(null);
    this.notificationService.setNotificationStatus(false);
    this.toaster.successToast('Logout success.');
    this.router.navigate(['/auth/login']);
  }
}
