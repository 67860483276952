import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppUser } from '../../core/models/users/appUser';
import { Observable } from 'rxjs';
import { UpdateUserRequest } from '../../core/models/users/updateUserRequest';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(private http: HttpClient) {}

  getUsers(fullName: string | null,showDeleted:boolean=true): Observable<AppUser[]> {
    var query = fullName ?? '';
    return this.http.get<AppUser[]>(`/users?fullName=${query}&showDeleted=${showDeleted}`);
  }
  
  getUser(userId: string, branchId: string): Observable<AppUser> {
    return this.http.get<AppUser>(`/users/${userId}?branchId=${branchId}`);
  }
  updateUser(
    userId: string,
    branchId: string,
    request: UpdateUserRequest
  ): Observable<any> {
    return this.http.put<any>(`/users/${userId}?branchId=${branchId}`, request);
  }
  deleteUser(userId: string, branchId: string): Observable<any> {
    return this.http.delete<any>(`/users/${userId}?branchId=${branchId}`);
  }
  activateAccount(userId: string, branchId: string) {
    return this.http.put<any>(
      `/users/${userId}/activate?branchId=${branchId}`,
      null
    );
  }
}
