import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { BaseComponent } from 'src/app/modules/core/components/base/base.component';
import { BroadcasterService } from 'src/app/modules/core/services/broadcaster.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [ConfirmationService],
})
export class SettingsComponent extends BaseComponent implements OnInit {
  constructor(
    broadcastService: BroadcasterService,
    authService: AuthenticationService,
    private confirmationService: ConfirmationService
  ) {
    super(broadcastService, authService);
  }

  override ngOnInit() {
    super.ngOnInit();
  }
  signOut() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Sign out',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.authService.logoutUser();
      },
      reject: () => {},
    });
  }
}
