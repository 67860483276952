import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getSensorLabelOrTag',
})
export class GetSensorLabelOrTagPipe implements PipeTransform {
  transform(label: string|undefined, tag: string): string {
    if (label && label != '') {
      return label;
    }
    const parts = tag.split('-');
    return parts.length > 0 ? parts[parts.length - 1] : '';
  }
}
