export abstract class BroadcastKeys {
  static readonly HTTP_LOADING = "HTTP_LOADING";
  static readonly MESSAGE_BOX_PARTICIPANT = "MESSAGE_BOX_PARTICIPANT";
  static readonly NEW_SENT_MESSAGE = "NEW_SENT_MESSAGE";
  static readonly CONVERSATION_DELETED = "CONVERSATION_DELETED";


  static readonly GROUP_EVENTS = "GROUP_EVENTS";
  static readonly NEW_GROUP_SENT_MESSAGE = "NEW_GROUP_SENT_MESSAGE";
  static readonly MESSAGE_GROUP_SELECTED = "MESSAGE_GROUP_SELECTED";
  static readonly NEW_MESSAGE_GROUP_CREATED = "NEW_MESSAGE_GROUP_CREATED";
  static readonly MESSAGE_GROUP_DELETED = "MESSAGE_GROUP_DELETED";
  
}
