import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getGatewayTag',
})
export class GetGatewayTagPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    const parts = value.split('-');
    return parts.length > 0 ? parts[parts.length - 1] : '';
  }
}
