import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials',
})
export class NameInitialsPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    // Split the sentence into words
    const words = value.split(' ');
    // Extract the first letter of each word
    const firstLetters = words.map((word) => word.charAt(0));
    return firstLetters.join('').toUpperCase();
  }
}
